<template>
  <v-dialog v-model="dialog" width="700" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="white--text mr-4" v-bind="attrs" v-on="on">
        Sincronizar Jornadas
      </v-btn>
    </template>

    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="pa-4 font-weight-bold accent">
        Sincronizar Jornadas
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-6">
        <p class="text-body-1">
          <strong>
            A sincronização de jornadas de trabalho é um processo que irá
            sincronizar as jornadas cadastradas dentro do cargo com cada
            colaborador vinculado a esse cargo.
          </strong>
          Para isso, é necessário que o cargo tenha uma jornada cadastrada e um
          colaborador esteja vinculado a esse cargo. Após feita a sincronização,
          será possível acessar e editar a jornada dentro do registro de cada
          colaborador.
        </p>

        <div>Tipo de Sincronização:</div>
        <v-btn-toggle
          v-model="sync_type"
          mandatory
          dense
          color="secondary"
          class="mb-6"
        >
          <v-btn value="geral" small> GERAL </v-btn>
          <v-btn value="novos" small> NOVOS </v-btn>
        </v-btn-toggle>

        <v-alert outlined v-show="sync_type == 'novos'">
          <h4>Sincronizar apenas novos:</h4>

          <p class="mt-3">
            Esse tipo de sincronização só irá sincronizar com colaboradores que
            não tem jornada de trabalho cadastrada. <br />
            <br />
            <strong>
              Não irá sobrescrever as jornadas de trabalho previamente
              sincronizadas com o colaborador.
            </strong>
          </p>
        </v-alert>

        <v-alert outlined v-show="sync_type == 'geral'">
          <h4>Sincronização geral:</h4>

          <p class="mt-3">
            Esse tipo de sincronização irá sincronizar todas as jornadas de
            trabalho cadastradas no cargo com todos os colaboradores vinculados
            ao cargo.
            <br />
            <br />
            <strong class="red--text">
              Irá sobrescrever as jornadas de trabalho previamente sincronizadas
              com o colaborador.
            </strong>
          </p>

          <p>
            Exemplo: <br />
            Se eu editei a jornada do colaborador (que foi previamente
            sincronizada) e mudei o horário de entrada de segunda feira para
            10:00 mas no cargo do colaborador o horário de entrada é 08:00, ao
            sincronizar de forma geral, o horário cadastrado no cargo irá
            sobrescrever o horário editado na jornada do colaborador e de todos
            os colaboradores que estão vinculados a esse cargo.
          </p>

          <v-checkbox
            v-model="confirm"
            label="Estou ciente que a sincronização geral irá sobrescrever dados
                previamente sincronizados!"
          >
          </v-checkbox>
        </v-alert>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="px-6 py-4">
        <v-btn outlined @click="dialog = !dialog"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="sincronizarJornadas"
          :disabled="disableSync"
        >
          Sincronizar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { syncJornadas } from "@/api/gestor/cargos.js";

export default {
  name: "SincronizarJornadas",

  data() {
    return {
      loading: false,
      dialog: false,
      sync_type: "novos",
      confirm: null,
    };
  },

  computed: {
    disableSync() {
      let result = false;

      if (this.sync_type == "geral" && !this.confirm) {
        result = true;
      }

      return result;
    },
  },

  methods: {
    async sincronizarJornadas() {
      try {
        this.loading = true;

        const response = await syncJornadas({ sync_type: this.sync_type });
        if (response.status === 201) {
          this.$toast.success("Sincronizado com sucesso!");
        }

        this.loading = false;
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toast.error("Erro ao sincronizar jornadas!");
      }
    },
  },
};
</script>

<style>
</style>